<template>
  <div class="box">
    <router-link
      v-if="$can('admin/pos/create')"
      :to="{ path: '/pos/create' }"
    >
      <el-button
        type="primary"
        size="medium"
      >
        新建POS
      </el-button>
    </router-link>

    <div class="box-toolbar">
      <div class="box-item">
        <el-input
          placeholder="请输入编号或备注名"
          v-model="search_name"
          clearable
          @keyup.enter.native="searchGo"
        >
        </el-input>
      </div>
      <div class="box-item">
        <el-button
          icon="el-icon-search"
          circle
          @click="searchGo()"
        ></el-button>
      </div>
    </div>
    <el-table
      v-loading="loading"
      border
      height="67vh"
      :stripe="true"
      :data="lists"
    >
      <!-- <el-table-column
        width="80"
        label="序号"
        prop="id"
      >
      </el-table-column> -->
      <el-table-column
        width="120"
        label="备注名"
        prop="name"
      >
      </el-table-column>

      <el-table-column
        label="编号"
        prop="number"
      >
      </el-table-column>

      <el-table-column
        label="店铺"
        prop="store_id"
      >
        <template slot-scope="scope">
          <p v-if="scope.row.store">{{scope.row.store.bsAddress || '未知店铺'}}</p>
        </template>
      </el-table-column>

      <el-table-column
        label="状态"
        prop="status"
      >
        <template slot-scope="scope">
          <el-button
            plain
            size='mini'
            type="primary"
            v-if="scope.row.status == 1"
          >开启</el-button>
          <el-button
            plain
            size="mini"
            type="warning"
            v-if="scope.row.status == 0"
          >禁用</el-button>
        </template>
      </el-table-column>

      <el-table-column
        label="创建时间"
        prop="created_at"
      >
      </el-table-column>

      <el-table-column
        fixed="right"
        width="190"
        label="操作"
      >
        <template slot-scope="scope">
          <el-dropdown trigger="click">
            <el-button
              plain
              size="mini"
              type="primary"
            >
              操作<i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                v-if="$can('admin/pos/update')"
                @click.native="handleModelEdit(scope.row)"
              >
                编辑
              </el-dropdown-item>
              <el-dropdown-item
                v-if="$can('admin/pos/delete')"
                @click.native="handleModelDelete(scope.row)"
              >
                删除
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </el-table-column>
    </el-table>

      <page
        class="shop-page"
        :pageSize="limit"
        :count="count"
        @change="pageChange"
        @changeSize="changeSize"
      >
      </page>
  </div>
</template>

<script>
import PosService from '@admin/services/PosService'
// import flatry from '@admin/utils/flatry'
import Page from '@admin/components/Pagination'
import pagination from '@admin/mixins/PaginationMixin'

export default {
  name: 'PosIndex',
  data () {
    return {
      search_name: '',
      function: function (param) {
        return PosService.all(param)
      },
      delete: function (id) {
        return PosService.delete(id)
      }
    }
  },
  components: {
    Page
  },
  mixins: [pagination],

  methods: {
    // 搜索吧~
    async searchGo () {
      this.page = 1
      let param = {
        type: this.type,
        type_name_number: this.search_name,
        store_id: this.shopId
      }
      this.where = param
      await this.getList(param)
    }
  }
}
</script>
<style lang='scss' scoped>
.shop-img {
    width: 50px;
    height: 50px;
}
.shop-i-bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 80px;
    .shop-page {
        float: right;
        margin: 0;
    }
}
.dialog-img {
    img {
        width: 300px;
        height: 300px;
    }
}
</style>
